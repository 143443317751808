import React from 'react';
import { Route, Redirect } from 'react-router-dom';
export const PrivateRoute = ({ component: Component, ...rest })=>(
  <Route {...rest} render={props=>{
      //eslint-disable-next-line
      if(localStorage.getItem('isAuthenticated') == 'loggedIn'){
          return <Component {...props} />
      }else{
          return <Redirect to={{ pathname: `/`, state: { from: props.location } }} />
      }
  }}/>
)
