import {Config} from "../config";
import {authHeader} from "../_helpers";
const API_PATH = Config().serverUrl
export const loginServices={
    getMetaData
}



function getMetaData() {
    const requestOptions = {
        method: 'GET',
        headers: authHeader()

    }
    return fetch(API_PATH+'getReference',requestOptions).then(handleResponse)
}


function handleResponse(response) {
   if(!response.ok){return Promise.reject()}else {return response.json()}
}
