import React, {Component} from 'react';
import ResultInChart from "./resultInChart";
import ResultInDataTable from "./resultInDataTable";


class Results extends Component {
    render() {
        return (
            <div>
                <ResultInChart
                    barChart={this.props.barChart}
                    range={this.props.range}
                    pageStyle={this.props.pageStyle}
                    getRangeWiseData={this.props.getRangeWiseData}
                    pieChart={this.props.pieChart}
                    customRangeItems={this.props.customRangeItems}
                />
                <ResultInDataTable
                    getDetails={this.props.getDetails}
                    dataLoading={this.props.dataLoading}
                    dataSorting={this.props.dataSorting}
                    direction={this.props.direction}
                    range={this.props.range}
                    data={this.props.data}
                    dataElements={this.props.dataElements}
                    detailsData={this.props.detailsData}
                    detailsCollections={this.props.detailsCollections}
                    dates={this.props.dates}
                    detail={this.props.detail}
                    dataElementsCollection={this.props.dataElementsCollection}
                    getDetailsByDate={this.props.getDetailsByDate}
                    getDetailBySelectedDate={this.props.getDetailBySelectedDate}
                    keys={this.props.keys}
                    selectedDate={this.props.selectedDate}
                    dateOptions={this.props.dateOptions}
                    pageOffset={this.props.pageOffset}
                    gotoNextDetailsPage={this.props.gotoNextDetailsPage}
                    gotoPrevPage={this.props.gotoPrevPage}
                    detailsLoading={this.props.detailsLoading}
                    detailsModalIsClosed={this.props.detailsModalIsClosed}
                    startDate={this.props.startDate}
                    endDate={this.props.endDate}
                    sortOrder={this.props.sortOrder}
                    datesForTab={this.props.datesForTab}
                    offset={this.props.offset}
                />


            </div>
        );
    }
}

export default Results;
