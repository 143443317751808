import {loginConst} from '../_constants'
const initialState = {};

export const loginReducer = (state = initialState, action) =>{

    switch (action.type) {
        case loginConst.USER_LOGIN_REQUEST:
            console.log('r',action)
            return {
                user: {},
                error: false,
                loading: true
            }
        case loginConst.USER_LOGIN_SUCCESS:

            return {
                user: action.user,
                loading: false,
                error: false
            }
        case loginConst.USER_LOGIN_FAILURE:

            return{
                user: '',
                loading: false,
                error: true
            }

        default:
            return state
    }

}
