import * as React from "react"

function FilterIcon() {
    return (
        <svg
            data-name="Filter Icon"
            width={16.327}
            height={16.666}
            viewBox="0 0 16.327 16.666"
        >
            <path
                data-name="Fill 221"
                d="M12.755 7.292H3.572A3.613 3.613 0 010 3.646 3.613 3.613 0 013.572 0h9.184a3.613 3.613 0 013.572 3.646 3.613 3.613 0 01-3.573 3.646zm-6.415-5.9a3.645 3.645 0 01.775 2.257A3.681 3.681 0 016.341 5.9h6.415a2.237 2.237 0 002.211-2.257 2.237 2.237 0 00-2.211-2.257H6.341zM3.558 1.4a2.208 2.208 0 00-2.187 2.016 2.537 2.537 0 000 .458 2.208 2.208 0 002.187 2.015 2.223 2.223 0 002.2-2.243 2.223 2.223 0 00-2.2-2.246z"
                fill="#748aa1"
            />
            <path
                data-name="Fill 222"
                d="M12.755 16.667H3.572A3.613 3.613 0 010 13.021a3.613 3.613 0 013.572-3.646h9.184a3.613 3.613 0 013.572 3.646 3.613 3.613 0 01-3.573 3.646zm-9.184-5.9a2.237 2.237 0 00-2.21 2.254 2.237 2.237 0 002.211 2.254h6.411a3.683 3.683 0 010-4.514zm9.2.014a2.223 2.223 0 00-2.2 2.243 2.195 2.195 0 004.382.229 1.968 1.968 0 000-.459 2.207 2.207 0 00-2.184-2.019z"
                fill="#748aa1"
            />
        </svg>
    )
}

export default FilterIcon
