import React, {useEffect, useState} from 'react';
import {loginAction} from "../../actions";
import {connect} from 'react-redux'
function Login(props) {
    const [password,setPassword] = useState('')
    const [errors,setErrors] = useState({})
    const changePassword=(e)=>{
        setPassword(e.target.value)
    }
    useEffect(()=>{
        localStorage.removeItem('isAuthenticated');


    })
    const submitLogin=()=>{
        const errors = {}
        if(!password) errors.password = 'Invalid pin code!';
        setErrors(errors);
        //eslint-disable-next-line
        if(Object.keys(errors).length == 0){
            let result = loginAction.login(password)
            //eslint-disable-next-line
            if(result == true){
                let isAuthenticated = 'loggedIn'
                localStorage.setItem('isAuthenticated',isAuthenticated);
                props.history.push("/home")
            }
        }
    }

    return (
        <div className="login">
            <div className="login-form">
                <fieldset>
                    <legend>Enter the pin code</legend>

                    <div className="user">
                        <input name="password" value={password} onChange={changePassword} type="password" placeholder="Enter pin code.."/>
                    </div>
                    {errors.password &&
                    <div><span className="text-danger">{errors.password}</span></div>
                    }
                    <div className="user text-right">

                        <button onClick={submitLogin} className="btn btn-primary">Let's Go</button>
                    </div>
                </fieldset>
            </div>
        </div>
    );
}

function mapStateToProps(state) {
    const { user,loading,error } = state.loginReducer;
    return {
        user,
        loading,
        error
    };
}

export default connect(mapStateToProps)(Login);
