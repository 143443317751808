import * as React from "react"

function PrintIcon() {
    return (
        <svg width={18.518} height={16.667} viewBox="0 0 18.518 16.667">
            <path
                data-name="Icon metro-print"
                d="M15.741 4.629H2.778A2.78 2.78 0 000 7.407v5.556h3.7v3.7h11.115v-3.7h3.7V7.407a2.78 2.78 0 00-2.778-2.778zm-2.782 10.185h-7.4v-4.63h7.4zm2.778-6.481a.926.926 0 11.926-.926.923.923 0 01-.926.926zM14.811 0H3.704v3.7h11.111V0z"
                fill="#748aa1"
            />
        </svg>
    )
}

export default PrintIcon
