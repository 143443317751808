import React, {Component} from 'react';
import {Doughnut,defaults} from 'react-chartjs-2'
import 'chartjs-plugin-datalabels';
import 'chart.piecelabel.js';
defaults.global.defaultColor = 'black';
defaults.global.defaultFontColor = '#444';
defaults.global.defaultFontStyle = '600';
defaults.global.defaultFontSize = 14;

class PieChart extends Component {
    constructor(props) {
        super(props);
        this.chartRef = React.createRef();
    }

    onElementsClick = (el) => {
        this.props.getRangeWiseData(el)
    }

    componentDidUpdate() {
        document.getElementById(
            "legend"
        ).innerHTML = this.chartRef.current.chartInstance.generateLegend();

    }

    render() {
        let thisObj = this;
       // console.log(this.props.customRangeItems)

        return (
            <div className="row flex-center">
                <div className="col-md-8">
                    <Doughnut
                        ref={this.chartRef}
                        onElementsClick={(elements) => this.onElementsClick(elements)}
                        data={this.props.data && this.props.data}
                        width={44}
                        height={31}
                        options={{

                            legendCallback: function (chart) {
                                const d = thisObj.props.customRangeItems;

                                let ul = document.createElement("ul");
                                ul.classList.add('legend-ul')
                                chart.data.datasets[0].backgroundColor.forEach((color, index) => {

                                    ul.innerHTML += `<li><span style="background: #fff; border-color: ${color}"></span> <div style="width: calc(100% - 50px)" class="d-flex align-center flex-column"><span class="legend-label">${chart.data.labels[index]}</span> <div class="content"><span>Total # of tests:</span>  <span class="total-number-of-test">${d[index].totalNumberOfTests}</span></div></li>`
                                })
                                return ul.outerHTML;
                            },

                            tooltips: {
                                callbacks: {
                                    label: function (tooltipItem, data) {
                                        let dataForToolTip = thisObj.props.customRangeItems
                                        let dataset = data.datasets[tooltipItem.datasetIndex];
                                        let currentValue = dataset.data[tooltipItem.index];
                                        return [data.datasets[0].label + ": " + (currentValue * 1).toFixed(2) + '%', 'Total Number of Tests: ' + dataForToolTip[tooltipItem.index].totalNumberOfTests, 'Total Number of Beneficiaries: ' + dataForToolTip[tooltipItem.index].totalNumberOfBenes]
                                    },
                                    title: function (tooltipItem, data) {
                                        return data.labels[tooltipItem[0].index];
                                    },

                                }
                            },
                            plugins: {
                                datalabels: {
                                    display: 'auto',
                                    color: 'white',
                                    value: 'percentage',
                                    clamp: true,
                                    formatter: (value, ctx) => {
                                        //eslint-disable-next-line
                                        if(value != 0) {
                                            let percentage = value.toFixed(2) + "%";
                                            return percentage;
                                        }else {
                                            return  null
                                        }
                                    },
                                },

                            },
                            legend: {
                                position: 'right',
                                display: false,
                            }
                        }}


                    />
                </div>
                <div className="col-md-4">
                    <div id="legend"></div>
                </div>


            </div>
        );
    }
}

export default PieChart;
