import React from 'react';
import {CSVLink} from 'react-csv';
import DatePicker from "react-datepicker";
import {Link} from "react-router-dom";
import "react-datepicker/dist/react-datepicker.css";
import './home.css'
import ReactToPrint from 'react-to-print';
import closeIcon from './close.png'
import FilterIcon from "../../_components/icons/filterIcon";
import PrintIcon from "../../_components/icons/printIcon";
import MultiSelect from "react-multi-select-component";
import Results from "../../_components/result/results";
import {filterServices} from "../../_services";
import SweetAlert from 'sweetalert2-react';
import moment from "moment";


class Home extends React.Component {
    constructor(props) {
        super(props);

        this.myRef = React.createRef();
        this.metaData = JSON.parse(localStorage.getItem('metaData'));

        this.state = {
            isShow: false,
            tableData: [],
            pageOffset: 1,
            totalLength: null,
            startingPoint: 0,
            perPage: 10,
            pageSize: 10,
            collapse: [],
            searchVal: "",
            converting: [],
            totalNumberOfTests: '',
            convertIntoJson: '',
            selectedDataSource: [],
            selectedElements: [],
            reverse: false,
            states: [],
            selectedState: 'All',
            allCounty: [],
            selectedCategories: [{label: 'Beneficiary', value: 'beneCategory'}],
            cities: [],
            city_name: '',
            process: false,
            show: false,
            processDownload: false,
            processComplete: false,
            page: 1,
            loading: false,
            dataLoading: false,
            zipCodes: [],
            selectedCode: [],
            drillDown: false,
            startDate: new Date(new Date().setMonth(new Date().getMonth() - 3)),
            endDate: new Date(),
            allDetails: false,
            open: false,
            activeKey: '',
            searchOpt: 'basic',
            selectedCounty: '',
            resultTitle: "",
            selectedProcedure: [],
            pieChart: {},
            barChart: {},
            range: '',
            applyDataSource: false,
            categories: [],
            procedureCodes: [],
            dataSources: [],
            sorting: {
                field: 'Total Cost of Testing',
                asc: true
            },
            specific: '',
            categoryCollection: [{label: 'Beneficiary', value: 'beneCategory'}],
            procedureCodeCollection: [],
            zipCodeCollection: [],
            dataElementsCollection: [],
            specificCategory: 'beneCategory',
            dates: [],
            detailsCollections: [],
            detail: [],
            dateOptions: [],
            keys: 0,
            selectedDate: [],
            detailsLoading: true,
            searchOptions: [
                {value: 'id', label: 'Beneficiary ID'},
                {value: 'Bene ID', label: 'Bene ID'},
                {value: 'Name', label: 'Name'},
                {value: 'Age', label: 'Age'},
                {value: 'Sex', label: 'Sex'},
                {value: 'Race', label: 'Race'},
                {value: 'State', label: 'State'},
                {value: 'County', label: 'County'},
                {value: 'Total_Tests', label: 'Total # of Tests'},
                {value: 'Total Cost of Testing', label: 'Total Cost of Testing'},
            ],
            customRangeItems: [],
            startRowNumber: 0,
            errors:{},
            cityCollection: [],

            sortOrder: [
                {
                    elementCode: "TBTCOST",
                    sortDirection: "DESC"
                },
                {
                    elementCode: "TBTCNT",
                    sortDirection: "DESC"
                },
                {
                    elementCode: "BNST",
                    sortDirection: "ASC"
                },
                {
                    elementCode: "BNCN",
                    sortDirection: "ASC"
                },
                {
                    elementCode: "BNCY",
                    sortDirection: "ASC"
                },
                {
                    elementCode: "BNZP",
                    sortDirection: "ASC"
                },
                {
                    elementCode: "BNMID",
                    sortDirection: "ASC"
                },
                {
                    elementCode: "BNNM",
                    sortDirection: "ASC"
                },
                {
                    elementCode: "BNAGE",
                    sortDirection: "ASC"
                },
                {
                    elementCode: "BNGN",
                    sortDirection: "ASC"
                },
                {
                    elementCode: "BNRC",
                    sortDirection: "ASC"
                }
            ],
            defaultSort: [
                {
                    elementCode: "TBTCOST",
                    sortDirection: "DESC"
                },
                {
                    elementCode: "TBTCNT",
                    sortDirection: "DESC"
                },
                {
                    elementCode: "BNST",
                    sortDirection: "ASC"
                },
                {
                    elementCode: "BNCN",
                    sortDirection: "ASC"
                },
                {
                    elementCode: "BNCY",
                    sortDirection: "ASC"
                },
                {
                    elementCode: "BNZP",
                    sortDirection: "ASC"
                },
                {
                    elementCode: "BNMID",
                    sortDirection: "ASC"
                },
                {
                    elementCode: "BNNM",
                    sortDirection: "ASC"
                },
                {
                    elementCode: "BNAGE",
                    sortDirection: "ASC"
                },
                {
                    elementCode: "BNGN",
                    sortDirection: "ASC"
                },
                {
                    elementCode: "BNRC",
                    sortDirection: "ASC"
                }
            ],
            pastOrder: {},
            datesForTab: [],


        }


    }

    //change the color of the graph for
    //activate it and send request to
    //get different category info
    getRangeWiseData = (elements) => {

        if (elements.length > 0) {
            this.getCategoriesInfo(elements[0]._index);

        }

    }


    //when clicked on each thumb of the graph
    //this function will be called to
    //fetch categories data for the data table
    getCategoriesInfo = (category) => {
        let range = this.metaData && this.metaData.testRanges.length > 0 && this.metaData && this.metaData.testRanges[category];
        let activeBorder = "red";
        let backgroundColor = [
            '#b558f6',
            '#2bcb97',
            '#fec402',
            '#215493',
            '#ff5c5c'
        ];
        let borderColor = ['transparent', 'transparent', 'transparent', 'transparent', 'transparent'];
        borderColor.splice(category, 1, activeBorder);

        this.setState({
            reverse: !this.state.reverse,
            //eslint-disable-next-line
            resultTitle: "Results " + range.name,
            dataLoading: true,
            range: range.code,
            pageSize: 10,
            page: 1,
            specific: '',
            pieChart: {
                labels: [...this.state.pieChart.labels],
                datasets: [
                    {
                        ...this.state.pieChart.datasets[0],
                        borderColor: borderColor,
                        backgroundColor: backgroundColor
                    }
                ]
            },
            barChart: {
                labels: [...this.state.barChart.labels],
                datasets: [
                    {
                        ...this.state.barChart.datasets[0],
                        borderColor: borderColor,
                        backgroundColor: backgroundColor
                    }
                ]
            }
        }, () => {
            this.getServiceData()
        })
    }


    setSelected = (selected) => {
        let mapped = [];
        //eslint-disable-next-line
        if (this.state.searchOpt == 'basic') {
            //eslint-disable-next-line
            selected.map(row => {
                mapped = mapped.concat(row.basicElements)
            })
        } else {
            //eslint-disable-next-line
            selected.map(row => {
                mapped = mapped.concat(row.mappedAdvance)
            })
        }

        this.setState({
            selected,
            selectedElements: mapped,
            process: false,
            show: false,

        })
    }

    selectedProcedure = (selected) => {
        this.setState({
            selectedProcedure: selected
        })
    }


    setSelectedElements = (selectedElements) => {
        this.setState({selectedElements})
    }


    changeCheckBox = (value) => {
        //eslint-disable-next-line
        const tobeKept = this.state.selectedElements.filter(element => element.value != value);
        this.setState({
            selectedElements: tobeKept
        })
    }


    getResult = () => {

        const errors = this.validateDateFirst();
        this.setState({errors})
        if(Object.keys(errors).length === 0 ) {
            this.setState({
                process: true,
                show: false,
                range: ''
            })

            let rearrangeOrder = this.state.selectedCategories;
            this.sortCategories(rearrangeOrder)
            setTimeout(() => {
                this.setState({
                    process: false,
                    show: true,
                    categoryCollection: rearrangeOrder,
                    specificCategory: this.state.selectedCategories[0].value
                }, () => {
                    this.getServiceData()
                })
                window.toggleFilter()
            }, 0)
        }
    }

    //ascending sorting
    //of selected categories
    sortCategories = (rearrangeOrder) => {
        return rearrangeOrder.sort((a, b) => a.value.localeCompare(b.value))
    }


    //change state
    setSelectedOption = (event) => {
        const {name, value} = event.target
        //eslint-disable-next-line
        if (name == 'selectedState') {
            this.setState({
                selectedState: value
            }, () => this.getCounty())
        }
        //eslint-disable-next-line
        if (name == 'selectedCounty') {
            this.setState({
                selectedCounty: value
            }, () => this.getCity())
        }
    }


    //get county on basis selected state

    getCounty = () => {
        let {selectedState} = this.state;

        filterServices.getCounty(selectedState).then(county => {
            this.setState({allCounty: county.postalCodes})

        })
    }

    //get city on basis selected county
    getCity = () => {
        filterServices.getCity(this.state.selectedCounty).then(city => {

            this.setState({
                cities: city.postalCodes
            })

        })

    }


    //change zipcode
    changeCode = (selectedCode) => {
        this.setState({
            selectedCode
        })
    }

    handleChange = date => {
        this.setState({
            startDate: date
        });
    };
    handleEndDate = date => {
        this.setState({
            endDate: date
        });
    };

    activateBen = (key) => {
        if (this.state.activateBen === key) {
            this.setState({
                activateBen: ''
            })
        } else {
            this.setState({
                activateBen: key
            })
        }
    }

    changeCity = (e) => {
        this.setState({
            city_name: e.target.value
        }, () => this.getAllZipCode())
    }


    getAllZipCode = () => {
        filterServices.getZipCodes(this.state.city_name).then(zipCode => {
            let codes = [];
            //eslint-disable-next-line
            zipCode.postalCodes && zipCode.postalCodes.length > 0 && zipCode.postalCodes.map(zipCode => {
                codes.push({label: zipCode.name, value: zipCode.code})
            })
            this.setState({zipCodes: codes})
        })

    }


    changeSearch = (e) => {
        const {name, value} = e.target;
        this.setState({
            [name]: value,
            selected: [],
            selectedElements: this.state.dataElementsCollection,
            applyDataSource: false,
            selectedSource: []
        })
    }


    //all functionalities for custom data table
    componentDidMount = () => {
        //this.getTableData();
        this.formatCategories();
        this.formatProcedureCodes();
        this.formatDataSources();
        setTimeout(() => {
            this.getServiceData()
        }, 0)

    }


    //needs to format the categories
    //from metaData to display
    //in the dropdown
    formatCategories = () => {
        let categories = [];
        //eslint-disable-next-line
        this.metaData && this.metaData.categories.map(category => {
            categories.push(
                {label: category.name, value: category.code}
            )
        })
        this.setState({categories})

    }
    //needs to format the categories
    //from metaData to display
    //in the dropdown
    formatProcedureCodes = () => {
        let procedureCodes = [];
        //eslint-disable-next-line
        this.metaData && this.metaData.procedureCodes.map(code => {
            procedureCodes.push(
                {label: code.name, value: code.code}
            )
        })
        this.setState({procedureCodes})
    }

    //needs to format the categories
    //from metaData to display
    //in the dropdown
    formatDataSources = () => {
        let dataSources = [];
        let dataElementsCollection = [];
        //eslint-disable-next-line
        this.metaData && this.metaData.datasources.map(source => {
            let basicElements = [];
            //eslint-disable-next-line
            source.basicElements.map(basicElement => {
                //eslint-disable-next-line
                let exist = this.metaData && this.metaData.elements.find(element => element.code == basicElement);
                if (exist) {
                    basicElements.push({label: exist.name, value: exist.code})
                }
            })
            dataSources.push({label: source.name, value: source.code, basicElements: basicElements})

        })
        //eslint-disable-next-line
        this.metaData && this.metaData.elements.map(element => {
            dataElementsCollection.push({label: element.name, value: element.code})
        })


        this.setState({
            dataSources,
            dataElementsCollection
        })
    }


    componentDidUpdate(prevProps, prevState, snapshot) {

        if (this.refs.res !== undefined && this.refs.filters !== undefined) {
            let height = this.refs.res.childNodes[0].childNodes[1].clientHeight;
            this.refs.filters.style.bottom = height + 'px'
        }


    }


    buildChartTableData = (chartData) => {
        const labels = [];
        const barChartData = []
        const pieChartData = []
        const totalNumber = chartData.totalNumberOfTests;
        const totalCost = chartData.totalCostOfTesting;
        const rangeItems = chartData.rangeItems;
        let customRangeItems = [
            {
                rangeCode: "RANGE_1_5",
                totalCostOfTesting: 0,
                totalNumberOfBenes: 0,
                totalNumberOfTests: 0,
            },
            {
                rangeCode: "RANGE_6_10",
                totalCostOfTesting: 0,
                totalNumberOfBenes: 0,
                totalNumberOfTests: 0,
            },
            {
                rangeCode: "RANGE_11_15",
                totalCostOfTesting: 0,
                totalNumberOfBenes: 0,
                totalNumberOfTests: 0,
            },
            {
                rangeCode: "RANGE_16_20",
                totalCostOfTesting: 0,
                totalNumberOfBenes: 0,
                totalNumberOfTests: 0,
            },
            {
                rangeCode: "RANGE_OVER_20",
                totalCostOfTesting: 0,
                totalNumberOfBenes: 0,
                totalNumberOfTests: 0,
            },

        ];
        //eslint-disable-next-line
        rangeItems.map(item => {
            let exist = customRangeItems.find(range => range.rangeCode === item.rangeCode);
            if (exist) {
                customRangeItems.splice(customRangeItems.indexOf(exist), 1, item)
            }
        })


        this.setState({
            totalNumberOfTests: totalNumber,
            customRangeItems
        },()=>{
            this.setState({processProps: !this.state.processProps})
        })
        //eslint-disable-next-line
        customRangeItems.length > 0 && customRangeItems.map(item => {
            let label = this.convertIntoLabel(item.rangeCode);
            let barData = this.convertIntoData(totalNumber, totalCost, item)
            let pieData = this.convertIntoData1(totalNumber, totalCost, item)
            labels.push(label)
            pieChartData.push(pieData)
            barChartData.push(barData)
        })

        let pieChart = {
            labels: labels,
            datasets: [
                {
                    label: 'Total % of Testing per Range',
                    borderColor: ['transparent', 'transparent', 'transparent', 'transparent', 'transparent'],
                    borderWidth: 1.5,
                    data: pieChartData,
                    backgroundColor: [
                        '#b558f6',
                        '#2bcb97',
                        '#fec402',
                        '#215493',
                        '#ff5c5c'
                    ]
                }
            ]
        }

        let barChart = {
            labels: labels,
            datasets: [
                {
                    label: 'Average Cost of Testing',
                    borderColor: ['transparent', 'transparent', 'transparent', 'transparent', 'transparent'],
                    borderWidth: 1.5,
                    data: barChartData,
                    backgroundColor: [
                        '#b558f6',
                        '#2bcb97',
                        '#fec402',
                        '#215493',
                        '#ff5c5c'
                    ]
                }
            ]
        }

        this.setState({
            pieChart,
            barChart
        })
    }

    convertIntoLabel = (labels) => {
        let label = '';
        const labelArgs = labels.toLowerCase().split('_');
        //eslint-disable-next-line
        if (labelArgs[1] == 'over') {
            label = labelArgs[0] + " > " + labelArgs[2];
        } else {
            label = labelArgs[0] + " " + labelArgs[1] + "-" + labelArgs[2]
        }
        return label;
    }

    convertIntoData = (totalNumber, totalCost, item) => {
        //eslint-disable-next-line
        let singlePrice = item.totalCostOfTesting !== 0 ? Math.ceil(item.totalCostOfTesting / item.totalNumberOfBenes) : 0
        return singlePrice
    }
    convertIntoData1 = (totalNumber, totalCost, item) => {
        let singleData1 = (item.totalNumberOfTests / totalNumber) * 100;
        return singleData1
    }
    getServiceData = () => {
        this.setState({dataLoading: true})
        let dataElements = [];
        let procedureCodes = [];
        let zipCodes = [];
        //eslint-disable-next-line
        this.state.dataElementsCollection.length > 0 && this.state.dataElementsCollection.map(element => {
            dataElements.push(element.value)
        })
        //eslint-disable-next-line
        this.state.selectedProcedure.map(procedureCode => {
            procedureCodes.push(procedureCode.value)
        })
        //eslint-disable-next-line
        this.state.selectedCode.length > 0 && this.state.selectedCode.map(zipCode => {
            zipCodes.push(zipCode.value)
        })

        let filterJSON = {
            filterConditions: {
                categoryCode: this.state.specificCategory,
                dataElements: dataElements,
                procedureCodes: procedureCodes,
                testingPeriodFrom: moment(this.state.startDate).format('YYYY-MM-DD'),
                testingPeriodTo: moment(this.state.endDate).format('YYYY-MM-DD'),
                //eslint-disable-next-line
                stateCodes: this.state.selectedState == 'All' ? [] : [this.state.selectedState],
                //eslint-disable-next-line
                countyCodes: this.state.selectedCounty != '' ? [this.state.selectedCounty] : [],
                cityCodes: this.state.city_name !== '' ? [this.state.city_name] : [],
                zipCodes: zipCodes
            },
            sortOrder: this.state.sortOrder,
            fetchSize: this.state.pageSize,
            startRowNumber: this.state.startRowNumber,
            skipRangeInfo: "false"
        }
        if (this.state.range !== '') {
            filterJSON.filterConditions.testRange = this.state.range;
        }



            filterServices.getServiceData(filterJSON).then(data => {

                if (data.testRangeData !== null && this.state.range === '') {
                    this.buildChartTableData(data.testRangeData);
                }
                if (data.beneSummaryRecords !== null && data.testRangeData !== null) {

                    let organizedData = [];
                    //eslint-disable-next-line
                    data.beneSummaryRecords.map(data => {
                        let beneData = {}
                        if (data.beneficiaryID) {
                            beneData['id'] = data.beneficiaryID
                        }
                        if (data.beneID) {
                            beneData['Bene ID'] = data.beneID
                        }
                        // if (data.beneName) {
                        //     beneData['Name'] = data.beneName
                        // }

                        if (data.age) {
                            beneData['Age'] = data.age
                        }else{
                            beneData['Age'] = 0
                        }
                        if (data.sex) {
                            beneData['Sex'] = data.sex
                        }
                        if (data.race) {
                            beneData['Race'] = data.race
                        }
                        if (data.state) {
                            beneData['State'] = data.state
                        }
                        if (data.county) {
                            beneData['County'] = data.county
                        }
                        if (data.totalNumberOfTests) {
                            beneData['Total_Tests'] = data.totalNumberOfTests
                        }
                        if (data.totalCostOfTesting) {
                            beneData['Total Cost of Testing'] = data.totalCostOfTesting
                        }
                        organizedData.push(beneData)
                    })
                    this.setState({
                        temp: organizedData,
                        totalLength: data.totalNumberOfRecords,
                        tableData: organizedData,
                        dataLoading: false,
                        stateCollection: this.state.selectedState,
                        countyCollection: this.state.selectedCounty,
                        cityCollection: this.state.city_name,
                        zipCodeCollection: this.state.selectedCode,
                        errors:{},

                    }, () => {
                        this.renderCsv()
                        this.renderXLsx()

                    })
                } else if (data.testRangeData !== null && data.beneSummaryRecords == null) {
                    this.setState({
                        ...this.state,
                        totalLength: data.totalNumberOfRecords,
                        tableData: [],
                        dataLoading: false,
                        stateCollection: this.state.selectedState,
                        countyCollection: this.state.selectedCounty,
                        cityCollection: this.state.city_name,
                        zipCodeCollection: this.state.selectedCode,
                        errors:{},

                    })
                } else {
                    this.setState({
                        ...this.state,
                        totalLength: null,
                        tableData: [],
                        dataLoading: false,
                        barChart: {},
                        pieChart: {},
                        stateCollection: this.state.selectedState,
                        countyCollection: this.state.selectedCounty,
                        cityCollection: this.state.city_name,
                        zipCodeCollection: this.state.selectedCode,
                        errors:{},

                    })
                }
            }).catch(error => {
                this.setState({dataLoading: false, isShow: true,errors:{}})
            })

    }

    validateDateFirst=()=>{
        let errors = {}
        const sum = moment(moment(this.state.endDate)).diff(moment(this.state.startDate));
        let minutes = parseInt(moment.duration(sum).asMinutes());
        if(minutes < 1) errors.start = 'Date cannot be after the Testing Period To date';
        return errors
    }

    renderCsv = () => {
        const header = [];
        const body = [];
        //eslint-disable-next-line
        this.state.tableData.length > 0 && this.state.tableData[0] && Object.keys(this.state.tableData[0]).filter(f => f != 'id' && f != 'key').map(heading => {
            header.push(heading)
        });
        //eslint-disable-next-line
        this.state.tableData.length > 0 && this.state.tableData.map(c => {
            let subRows = [];
            //eslint-disable-next-line
            Object.keys(this.state.tableData[0]).filter(f => f != 'id' && f != 'kye').map(h => {
                subRows.push(c[h])
            });
            body.push(subRows)
        })
        body.unshift(header)
        this.setState({
            converting: body
        })

    }

    renderXLsx = () => {
        const dataInObjectFormat = this.state.tableData;

        let convertIntoJson = []
        //eslint-disable-next-line
        this.state.tableData.length > 0 && dataInObjectFormat.map(r => {
            convertIntoJson.push(r)

        })

        this.setState({
            convertIntoJson: JSON.stringify(convertIntoJson)
        })
    }

    searchBeforeGo = (rows) => {
        const columns = rows[0] && Object.keys(rows[0]);
        let index = 0;
        return rows.filter(row => {
            //eslint-disable-next-line
            if (this.state.specific != '' && this.state.specific != 'all') {
                //eslint-disable-next-line
                return row[this.state.specific] != undefined && row[this.state.specific].toString().toLowerCase().indexOf(this.state.searchVal.toString().toLowerCase(), 0) == index
            } else {
                //eslint-disable-next-line
                return columns.filter(c => c != 'id').some((column) => {
                    //eslint-disable-next-line
                    return row[column] != undefined && row[column].toString().toLowerCase().indexOf(this.state.searchVal.toString().toLowerCase(), 0) == index

                })
            }
        })
    }


    searchData = (e) => {
        this.setState({
            searchVal: e.target.value
        }, () => {
            this.getServiceDataBySearchVal()
        })
    }
    //page label searching
    getServiceDataBySearchVal = () => {
        let temp = this.state.temp;
        let filteredData = this.searchBeforeGo(temp);
        this.setState({tableData: filteredData})

    }

    getNextPage = () => {
        let offset = (this.state.page*1) + 1
        if (Math.ceil(this.state.totalLength / this.state.pageSize) > this.state.page) {
            this.setState({
                searchVal: "",
                startRowNumber: (this.state.pageSize * this.state.page *1 ) + 1,
                page: offset,
            }, () => {
                this.getServiceData()
            })
        }
    }
    getPreviousPage = () => {
        let offset = (this.state.page*1) - 1
        if (this.state.page > 1) {
            this.setState({
                searchVal: "",
                page: offset,
                startRowNumber: (this.state.startRowNumber*1)-(this.state.pageSize*1),
            }, () => {
                this.getServiceData()
            })
        }
    }
    changeHandler = (e) => {
        const {name, value} = e.target;
        //eslint-disable-next-line
        if (name == 'specific') {
            this.setState({
                [name]: value,
            })
        }
        //eslint-disable-next-line
        let validSize = Math.ceil(this.state.totalLength / this.state.pageSize)
        //eslint-disable-next-line
        if (name == 'page') {
            if (e.target.value < validSize) {
                this.setState({
                    page: e.target.value,
                    startRowNumber: (this.state.pageSize*e.target.value*1) + 1
                }, () => {
                    this.getServiceData()
                })
            }
        }
        //eslint-disable-next-line
        else if (name == 'pageSize') {
            this.setState({
                [name]: value,
                page: 1,
                searchVal: this.state.tableData.length > 0 ? this.state.searchVal : '',
                startRowNumber: 0,
                specific:''
            }, () => {
                this.getServiceData()
            })
        }
        //eslint-disable-next-line
        else if (name == 'searchVal') {
            this.setState({
                [name]: value,
            }, () => {
                this.getServiceData()
            })
        }

    }

    generateXLXS = () => {
        const {convertIntoJson} = this.state;
        let formattingRequiredData = [];
        //eslint-disable-next-line
        JSON.parse(convertIntoJson).map(data => {
            delete data.id;
            delete data.key;
            formattingRequiredData.push(data)
        })
        window.generateXLsx(formattingRequiredData)
    }

    dataSorting = (column) => {
        // eslint-disable-next-line
        let exist = this.state.sortOrder.find(item=>item.elementCode == column)
        // eslint-disable-next-line
       let newOrder = this.state.defaultSort.filter(item => item.elementCode != column);
        // eslint-disable-next-line
        newOrder.unshift({elementCode: column,sortDirection: exist.sortDirection == 'ASC' ? 'DESC' : 'ASC'})

        this.setState({sortOrder: newOrder},()=>{
            this.getServiceData()
        })
    }


    setSelectedCategories = (category) => {
        this.setState({
            selectedCategories: category
        })
    }


    //select data source
    selectDataSources = (dataSource) => {
        let mapped = [];
        //eslint-disable-next-line
        if (this.state.searchOpt == 'basic') {
            //eslint-disable-next-line
            dataSource.map(row => {
                mapped = mapped.concat(row.basicElements)
            })
        } else {
            //eslint-disable-next-line
            dataSource.map(row => {
                mapped = mapped.concat(row.basicElements)
            })
        }

        this.setState({
            selectedDataSource: dataSource,
            selectedElements: mapped,
            dataElementsCollection: mapped,
            process: false,
            show: false,
            applyDataSource: false
        })
    }


    applyCategory = () => {
        this.setState({
            categoryCollection: this.state.selectedCategories
        })
    }

    applyCodes = () => {
        this.setState({
            procedureCodeCollection: this.state.selectedProcedure
        })
    }
    applyZipCodes = () => {
        this.setState({
            zipCodeCollection: this.state.zipCodes
        })
    }
    applyElements = (elements) => {
        this.setState({
            dataElementsCollection: elements
        })
    }


    findStateName = (code) => {
        //eslint-disable-next-line
        let exist = this.metaData && this.metaData.states.find(state => state.code == code);
        if (exist) {
            return exist.name
        }
    }
    findCounty = (code) => {
        if (this.state.allCounty.length > 0) {
            //eslint-disable-next-line
            let exist = this.state.allCounty.find(county => county.code == code);
            if (exist) {
                return exist.name
            }
        }
    }
    findCity = (code) => {
        if (this.state.cities && this.state.cities.length > 0) {
            //eslint-disable-next-line
            let exist = this.state.cities.find(city => city.code == code);
            if (exist) {
                return exist.name
            }
        }
    }

    toggleFilterDropdown = () => {
        window.toggleFilter()
        //eslint-disable-next-line
        if (this.state.show == false) {
            this.setState({
                selectedState: 'All',
                selectedCounty: '',
                city_name: '',
                selectedCategories: [{label: 'Beneficiary', value: 'beneCategory'}],
                selectDataSources: [],
                selectedProcedure: [],
                startDate: new Date(new Date().setMonth(new Date().getMonth() - 3)),
                endDate: new Date(),
                selectedCode: [],
                range: '',
                selectedDataSource: [],
                errors:{}
            })
        }
    }



    showSpecificCategory = (category) => {
        this.setState({
            specificCategory: category
        })
    }


    //get the details
    getDetails = (id) => {
        let dataElements = [];
        let procedureCodes = [];
        let zipCodes = [];
        //eslint-disable-next-line
        this.state.dataElementsCollection.length > 0 && this.state.dataElementsCollection.map(element => {
            dataElements.push(element.value)
        })
        //eslint-disable-next-line
        this.state.procedureCodeCollection.map(procedureCode => {
            procedureCodes.push(procedureCode.value)
        })
        //eslint-disable-next-line
        this.state.selectedCode.length > 0 && this.state.selectedCode.map(zipCode => {
            zipCodes.push(zipCode.value)
        })
        let filterJSON = {
            filterConditions: {
                categoryCode: this.state.specificCategory,
                dataElements: dataElements,
                procedureCodes: procedureCodes,
                testingPeriodFrom: moment(this.state.startDate).format('YYYY-MM-DD'),
                testingPeriodTo: moment(this.state.endDate).format('YYYY-MM-DD'),
                stateCodes: [],
                //eslint-disable-next-line
                countyCodes: this.state.selectedCounty != '' ? [this.state.selectedCounty] : [],
                cityCodes: this.state.city_name !== '' ? [this.state.city_name] : [],
                zipCodes: zipCodes,
                beneficiaryId: id
            }


        }
        this.setState({
            detailsLoading: true
        })
        filterServices.getDetails(filterJSON).then(details => {
            let detailsData = []
            let dates = [];
            let detail = [];
            let datesForTab = [];
            //eslint-disable-next-line
            details.beneRecords.map((record, key) => {
                detailsData.push(record.beneRecord)
            })

            this.setState(detailsData)
            //eslint-disable-next-line
            detailsData.map((data, index) => {

                //eslint-disable-next-line
                data.map((item, key) => {
                    //eslint-disable-next-line
                    if (item.element == 'TSORDT') {
                        // eslint-disable-next-line
                        let exist = dates.find(date=> date.label == item.value)

                        if(!exist){
                            dates.push(
                                {label: item.value, value: item.value + "_" + index}
                            )
                        }

                        datesForTab.push(
                            {label: item.value, value: item.value + "_" + index}
                        )

                    }
                })
            })
            //eslint-disable-next-line
            detailsData.slice(this.state.perPage * this.state.pageOffset - this.state.perPage, this.state.perPage * this.state.pageOffset).map(d => {
                detail.push(d)
            })
            let detailsCollections = this.dataElementCollections(detailsData)

            let dateOptions = [];
            //eslint-disable-next-line
            datesForTab.slice(this.state.perPage * this.state.pageOffset - this.state.perPage, this.state.perPage * this.state.pageOffset).map(d => {
                dateOptions.push(d)
            })
            this.sortByAscendingOrder(dateOptions);
            //eslint-disable-next-line
            detail.map(singleRow => {
                //eslint-disable-next-line
                let exist = singleRow.find(item => item.value == dateOptions[0].label)
                if (exist) {
                    detail.splice(detail.indexOf(singleRow), 1);
                    detail.unshift(singleRow)
                }

            })


            this.setState({
                detail,
                dates,
                detailsCollections,
                detailsData,
                dateOptions,
                datesForTab,
                detailsLoading: false

            })

        }).catch(error => {
            this.setState({detailsLoading: false})
            error.json().then(err => {
                alert(err.message)
            })
        })


    }


    dataElementCollections = (detailsData) => {
        let detailsObject = [];
        if (detailsData[0].length > 0) {
            //eslint-disable-next-line
            detailsData[0].filter(d => d.element != "BNID" && d.element != "BNNM").map(element => detailsObject.push(element.element))

        }

        var size = 5;
        var detailsCollections = [];
        for (var i = 0; i < detailsObject.length; i += size) {
            detailsCollections.push(detailsObject.slice(i, i + size));
        }

        return detailsCollections
    }

    sortByAscendingOrder = (dates) => {
        return dates.sort((a, b) => new Date(a.label).getTime() - new Date(b.label).getTime())
    }

    getDetailsByDate = (key, date) => {

        let details = this.state.detail;
        //eslint-disable-next-line
        this.state.detail.map(detail => {
            //eslint-disable-next-line
            let exist = detail.find(item => item.value == date)

            if (exist) {
                details.splice(details.indexOf(detail), 1);
                details.unshift(detail)
            }

        })
        this.setState({detail: details, keys: key})
    }

    getDetailBySelectedDate = (date) => {

        if (date.length > 0 && date.length < this.state.dates.length) {
            this.setState({
                pageOffset: 1,
                keys: 0
            },()=>{
                let details = [];
                let detail = [];
                let dateOptions = [];
                let datesForTab = [];

                //eslint-disable-next-line
                date.map(date => {
                    //eslint-disable-next-line
                    this.state.detailsData.map((detail,key) => {
                        //eslint-disable-next-line
                        let exist = this.state.detailsData[key].find(item=>item.value == date.label);
                        if(exist){
                            details.push(detail)
                        }
                    })
                })



                //eslint-disable-next-line
                details.slice(this.state.perPage * this.state.pageOffset - this.state.perPage, this.state.perPage * this.state.pageOffset).map(d => {
                    detail.push(d)
                })
                // eslint-disable-next-line
                details.map((item,key)=>{
                    // eslint-disable-next-line
                    details[key].map(date=>{
                        // eslint-disable-next-line
                        if(date.element == 'TSORDT'){
                            datesForTab.push({label: date.value,value: date.value+'_'+key })
                        }
                    })
                });
                // eslint-disable-next-line
                datesForTab.slice(this.state.perPage * this.state.pageOffset - this.state.perPage, this.state.perPage * this.state.pageOffset).map(d => {
                    dateOptions.push(d)
                })



                this.sortByAscendingOrder(dateOptions);
                //eslint-disable-next-line
                detail.map(singleRow => {
                    //eslint-disable-next-line
                    let exist = singleRow.find(item => item.value == date[0].label)

                    if (exist) {
                        detail.splice(detail.indexOf(singleRow), 1);
                        detail.unshift(singleRow)
                    }

                })

                this.setState({detail, selectedDate: date, dateOptions, datesForTab})
            })

        } else {
            let dates = [];
            let detail = [];
            let datesForTab = [];
            this.setState({
                pageOffset: 1,
                keys: 0
            }, () => {
                //eslint-disable-next-line
                this.state.detailsData.map((data, index) => {
                    //eslint-disable-next-line
                    data.map((item, key) => {
                        //eslint-disable-next-line
                        if (item.element == 'TSORDT') {
                            // eslint-disable-next-line
                            let exist = dates.find(date=> date.label == item.value)

                            if(!exist){
                                dates.push(
                                    {label: item.value, value: item.value + "_" + index}
                                )
                            }

                            datesForTab.push(
                                {label: item.value, value: item.value + "_" + index}
                            )

                        }
                    })
                })
                //eslint-disable-next-line
                this.state.detailsData.slice(this.state.perPage * this.state.pageOffset - this.state.perPage, this.state.perPage * this.state.pageOffset).map(d => {
                    detail.push(d)
                })

                let dateOptions = [];
                //eslint-disable-next-line
                datesForTab.slice(this.state.perPage * this.state.pageOffset - this.state.perPage, this.state.perPage * this.state.pageOffset).map(d => {
                    dateOptions.push(d)
                })
                this.sortByAscendingOrder(dateOptions);

                // eslint-disable-next-line
                detail.map(singleRow => {
                    //eslint-disable-next-line
                    let exist = singleRow.find(item => item.value == dateOptions[0].label)
                    if (exist) {
                        detail.splice(detail.indexOf(singleRow), 1);
                        detail.unshift(singleRow)
                    }

                })


                this.setState({detail, selectedDate: date, dateOptions, dates,datesForTab})
            })

        }
    }
    gotoNextDetailsPage = () => {
        this.setState({
            pageOffset: this.state.pageOffset + 1
        }, () => {

                let detail = []
                let dateOptions = [];
                //eslint-disable-next-line
                this.state.datesForTab.slice(this.state.perPage * this.state.pageOffset - this.state.perPage, this.state.perPage * this.state.pageOffset).map(d => {
                    dateOptions.push(d)
                })
                this.sortByAscendingOrder(dateOptions)
                //eslint-disable-next-line
                this.state.detailsData.slice(this.state.perPage * this.state.pageOffset - this.state.perPage, this.state.perPage * this.state.pageOffset).map(d => {
                    detail.push(d)
                })
                //eslint-disable-next-line
                detail.map(singleRow => {
                    //eslint-disable-next-line
                    let exist = singleRow.find(item => item.value == dateOptions[0].label)

                    if (exist) {
                        detail.splice(detail.indexOf(singleRow), 1);
                        detail.unshift(singleRow)
                    }

                })

                this.setState({
                    detail,
                    keys: 0,
                    dateOptions
                })


        })

    }
    gotoPrevPage = () => {

        this.setState({
            pageOffset: this.state.pageOffset - 1
        }, () => {
            let details = []
            let dateOptions = [];
            //eslint-disable-next-line
            this.state.datesForTab.slice(this.state.perPage * this.state.pageOffset - this.state.perPage, this.state.perPage * this.state.pageOffset).map(d => {
                dateOptions.push(d)
            })
            //eslint-disable-next-line
            this.state.detailsData.slice(this.state.perPage * this.state.pageOffset - this.state.perPage, this.state.perPage * this.state.pageOffset).map(d => {
                details.push(d)
            })
            this.sortByAscendingOrder(dateOptions)
            //eslint-disable-next-line
            details.map(singleRow => {
                //eslint-disable-next-line
                let exist = singleRow.find(item => item.value == dateOptions[0].label)

                if (exist) {
                    details.splice(details.indexOf(singleRow), 1);
                    details.unshift(singleRow)
                }

            })


            this.setState({
                detail: details,
                keys: 0,
                dateOptions
            })
        })

    }

    detailsModalIsClosed = () => {
        this.setState({
            keys: 0,
            pageOffset: 1,
            dates: [],
            dateOptions: [],
            detail: [],
            detailsData: [],
            selectedDate: []
        })
    }

    getZipCodesList = () => {
        return this.state.selectedCode.map((zipCode, key) => {
            let separator = '';
            if (key > 0) {
                separator = ','
            }
            return separator + " " + zipCode.value
        })
    }


    render() {

        const {selectedElements, dataElementsCollection, selectedProcedure, selectedCategories, selectedDataSource} = this.state;

        return (
            <React.Fragment>

                <SweetAlert
                    show={this.state.isShow}
                    title="Error!"
                    text="Unable to load data!  Please contact the Help Desk for support."
                    onConfirm={() => this.setState({isShow: false})}
                />

                <div className="overlay"></div>
                <header>
                    <div className="header-top">
                        <div className="container">
                            <div className="header-wrapper">
                                <div className="row">

                                    <div className="col-md-3">
                                        <div className="d-flex align-center">
                                            <div className="logo">
                                                <span className="logo-label"><Link title="Acda Logo"
                                                                                   to="/home">ACDA</Link></span>
                                                <span> | </span> <span>ACME/DASH Demo</span>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-md-4 col-md-offset-1">
                                        <div className="custom-buttons">
                                            {this.state.categoryCollection.length > 0 && this.state.categoryCollection.map((collection, key) => {
                                                return <button key={key}
                                                               className={
                                                                   //eslint-disable-next-line
                                                                   this.state.specificCategory == collection.value ? 'btn btn-custom-primary' : 'btn btn-default'}
                                                               onClick={() => this.showSpecificCategory(collection.value)}>{collection.label}</button>
                                            })}
                                        </div>
                                    </div>
                                    <div className="col-md-3 col-md-offset-1 d-flex justify-right">
                                        <div className="d-flex align-center right-buttons">
                                            <div className="position-relative">
                                                Search Filter &nbsp; &nbsp;
                                                <button onClick={this.toggleFilterDropdown}
                                                        className="btn btn-default"><FilterIcon/></button>
                                                <div className="position-abs collapsible-filter">
                                                    <div className="row">
                                                        <div className="col-md-12">
                                                            <div
                                                                className="single-search d-flex align-center justify-between">

                                                                <div className="d-flex" style={{width: '300px'}}>
                                                                    <label className="radio-inline d-flex align-center">
                                                                        <input
                                                                            defaultChecked
                                                                            value="basic"
                                                                            onChange={this.changeSearch}
                                                                            type="radio"
                                                                            name="searchOpt"/><span>Basic Search</span>
                                                                    </label>
                                                                    <label className="radio-inline d-flex align-center">
                                                                        <input
                                                                            value="advance"
                                                                            onChange={this.changeSearch}
                                                                            type="radio"
                                                                            name="searchOpt"/><span>Advanced Search</span>
                                                                    </label>
                                                                </div>

                                                                <span
                                                                    onClick={() => this.toggleFilterDropdown()}><img
                                                                    width="15" src={closeIcon} alt=""/></span>


                                                            </div>
                                                        </div>
                                                    </div>

                                                    <div className="row">
                                                        <div className="col-md-6">
                                                            <div className="single-search">
                                                                <label className="label_inline">Category <span
                                                                    className="text-danger">*</span></label>
                                                                {this.state.categories.length > 0 &&
                                                                <MultiSelect
                                                                    options={this.state.categories}
                                                                    value={selectedCategories}
                                                                    onChange={this.setSelectedCategories}
                                                                    labelledBy={"Select Category"}
                                                                ></MultiSelect>
                                                                }
                                                            </div>
                                                        </div>
                                                        <div className="col-md-6">

                                                            <div className="single-search">
                                                                <label className="label_inline">Data Sources <span
                                                                    className="text-danger">*</span></label>
                                                                {this.state.dataSources.length > 0 &&
                                                                <MultiSelect
                                                                    options={this.state.dataSources}
                                                                    value={selectedDataSource}
                                                                    onChange={this.selectDataSources}
                                                                    labelledBy={"Select Data Sources"}
                                                                ></MultiSelect>
                                                                }
                                                            </div>

                                                        </div>
                                                    </div>


                                                    <div className="row">
                                                        <div className="col-md-12">
                                                            {   //eslint-disable-next-line
                                                                selectedElements.length > 0 &&
                                                                <div className={
                                                                    //eslint-disable-next-line
                                                                    this.state.searchOpt == 'advance' ? 'single-search advnaced' : 'single-search'}>
                                                                    {   //eslint-disable-next-line
                                                                        selectedElements.length > 0 &&
                                                                        selectedElements &&
                                                                        <div className="single-search">
                                                                            <label className="label_inline">Data
                                                                                Elements <span
                                                                                    className="text-danger">*</span></label>
                                                                            {this.state.dataSources.length > 0 &&
                                                                            <MultiSelect
                                                                                options={this.state.selectedElements}
                                                                                value={dataElementsCollection}
                                                                                onChange={this.applyElements}
                                                                                labelledBy={"Select Data Elements "}
                                                                            ></MultiSelect>
                                                                            }
                                                                        </div>
                                                                    }

                                                                </div>}
                                                        </div>

                                                    </div>
                                                    <div className="row">
                                                        {this.state.procedureCodes.length > 0 &&
                                                        <div className="col-md-12">
                                                            <div className="single-search">
                                                                <label className="label_inline">Procedure Codes</label>

                                                                <MultiSelect
                                                                    options={this.state.procedureCodes}
                                                                    value={selectedProcedure}
                                                                    onChange={this.selectedProcedure}
                                                                    labelledBy={"Select Codes"}
                                                                ></MultiSelect>


                                                            </div>
                                                        </div>
                                                        }
                                                    </div>


                                                    <div className="row">
                                                        <div className="col-md-6">
                                                            <div className="single-search">
                                                                <div className="start-date date">
                                                                    <div className="inline_label">Testing Period
                                                                        From
                                                                    </div>
                                                                    <DatePicker
                                                                        selected={this.state.startDate}
                                                                        onChange={this.handleChange}
                                                                    />
                                                                    <div className="calendar-icon"><i
                                                                        className="fa fa-calendar"></i></div>
                                                                </div>
                                                                {this.state.errors.start && <p className="text-danger">{this.state.errors.start}</p>}
                                                            </div>

                                                        </div>
                                                        <div className="col-md-6">
                                                            <div className="single-search">
                                                                <div className="end-date date">
                                                                    <div className="inline_label">Testing Period
                                                                        To
                                                                    </div>
                                                                    <DatePicker
                                                                        selected={this.state.endDate}
                                                                        onChange={this.handleEndDate}
                                                                    />
                                                                    <div className="calendar-icon"><i
                                                                        className="fa fa-calendar"></i></div>
                                                                </div>

                                                            </div>

                                                        </div>
                                                    </div>


                                                    <div className="row">
                                                        <div className="col-md-6">
                                                            <div className="single-search">
                                                                <div className="custom-input-border">
                                                                    <div>State (optional)</div>
                                                                    <select
                                                                        value={this.state.selectedState}
                                                                        name="selectedState"
                                                                        onChange={this.setSelectedOption}
                                                                        className="form-control">
                                                                        <option value="All">Select State</option>
                                                                        {this.metaData && this.metaData.states.length > 0 && this.metaData && this.metaData.states.map((state, key) => {
                                                                            return <option key={key}
                                                                                           value={state.code}>{state.name}</option>
                                                                        })}
                                                                    </select>
                                                                </div>


                                                            </div>

                                                        </div>
                                                        <div className="col-md-6">
                                                            <div className="single-search">
                                                                <div className="custom-input-border">
                                                                    <div>County (optional)</div>
                                                                    <select
                                                                        value={this.state.selectedCounty}
                                                                        name="selectedCounty"
                                                                        onChange={this.setSelectedOption}
                                                                        className="form-control">
                                                                        <option>Select County</option>
                                                                        {this.state.allCounty.length > 0 && this.state.allCounty.map((county, key) => {
                                                                            return <option key={key}
                                                                                           value={county.code}>{county.name}</option>
                                                                        })}
                                                                    </select>
                                                                </div>
                                                            </div>

                                                        </div>
                                                    </div>
                                                    <div className="row">
                                                        <div className="col-md-6">
                                                            <div className="single-search">
                                                                <div className="custom-input-border">
                                                                    <div>City (optional)</div>
                                                                    <select
                                                                        value={this.state.city_name}
                                                                        name="city_name"
                                                                        onChange={this.changeCity}
                                                                        className="form-control">
                                                                        <option>Select City</option>
                                                                        {
                                                                            this.state.cities.length > 0 && this.state.cities.map((city, key) => {
                                                                                return <option key={key}
                                                                                               value={city.code}>{city.name}</option>
                                                                            })}
                                                                    </select>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="col-md-6">
                                                            <div className="single-search">

                                                                <label className="label_inline">Zip Code
                                                                    (optional)</label>

                                                                <MultiSelect
                                                                    value={this.state.selectedCode}
                                                                    onChange={this.changeCode}
                                                                    options={this.state.zipCodes}
                                                                    labelledBy={"Zip Code"}
                                                                />

                                                            </div>
                                                        </div>
                                                    </div>


                                                    <div className="single-search text-right">


                                                        <button onClick={this.getResult}
                                                                className="btn btn-primary  btn-custom-primary">
                                                            {   // eslint-disable-next-line
                                                                this.state.process == true ? <span><i
                                                                    className="fa fa-spinner fa-spin"></i> Request Data</span> : 'Request Data'}</button>


                                                    </div>


                                                </div>

                                            </div>

                                            <div>
                                                <ReactToPrint
                                                    trigger={this.renderPrintButton}
                                                    content={() => this.componentRef}
                                                    pageStyle={""}/>
                                            </div>
                                        </div>
                                    </div>

                                </div>

                            </div>
                        </div>
                    </div>
                    <div className="header-bottom">
                        <div className="container">

                            <div className="row">
                                <div className="col-md-12">
                                    <div className="search-hints">
                                        {   //eslint-disable-next-line
                                            this.state.show == true &&
                                            <p>
                                                State: &nbsp; {this.state.selectedState !== 'All' ?
                                                <span>{this.findStateName(this.state.stateCollection)}</span> :
                                                <span>All</span>}
                                                {   //eslint-disable-next-line
                                                    this.state.countyCollection != '' ?
                                                        <span><strong>County: </strong>&nbsp; {this.findCounty(this.state.countyCollection)}</span> : ''}
                                                {   //eslint-disable-next-line
                                                    this.state.cityCollection != '' ?
                                                        <span> <strong>City: </strong>&nbsp; {this.findCity(this.state.cityCollection)}</span> : ''}
                                                {this.state.zipCodeCollection.length > 0 ?
                                                    <span><strong>Zip Codes: </strong>&nbsp; {this.getZipCodesList()}</span> : ''}

                                            </p>
                                        }
                                        {
                                            //eslint-disable-next-line
                                            this.state.show == false &&
                                            <p>
                                                State: &nbsp;
                                                <span>All</span>

                                            </p>
                                        }
                                    </div>
                                </div>
                            </div>

                        </div>
                    </div>

                </header>

                <div className="main-content">
                    <div className="container">
                        {   //eslint-disable-next-line
                            this.state.specificCategory == 'beneCategory' &&
                            <div className="row">
                                <div className="col-md-12">
                                    <div className="position-relative">
                                        {   // eslint-disable-next-line
                                            this.state.dataLoading == true &&
                                            <div className="preloader">
                                                <span className="fa fa-spinner fa-spin fa-3x"></span>
                                            </div>
                                        }

                                        {Object.keys(this.state.barChart).length > 0 &&

                                        <div id="results" ref="res">
                                            <Results
                                                barChart={this.state.barChart}
                                                button={this.renderButton}
                                                ref={el => (this.componentRef = el)}
                                                pageStyle="@page { size: A2 portrait;}"
                                                getRangeWiseData={this.getRangeWiseData}
                                                pieChart={this.state.pieChart}
                                                dataLoading={this.state.dataLoading}
                                                dataSorting={this.dataSorting}
                                                direction={this.state.sorting}
                                                range={this.state.range}
                                                data={this.state.tableData}
                                                dataElements={this.state.selectedElements}
                                                getDetails={this.getDetails}
                                                detailsData={this.state.detailsData}
                                                detailsCollections={this.state.detailsCollections}
                                                dates={this.state.dates}
                                                detail={this.state.detail}
                                                dataElementsCollection={this.state.dataElementsCollection}
                                                getDetailsByDate={this.getDetailsByDate}
                                                getDetailBySelectedDate={this.getDetailBySelectedDate}
                                                keys={this.state.keys}
                                                selectedDate={this.state.selectedDate}
                                                dateOptions={this.state.dateOptions}
                                                pageOffset={this.state.pageOffset}
                                                gotoNextDetailsPage={this.gotoNextDetailsPage}
                                                gotoPrevPage={this.gotoPrevPage}
                                                detailsLoading={this.state.detailsLoading}
                                                detailsModalIsClosed={this.detailsModalIsClosed}
                                                customRangeItems={this.state.customRangeItems}
                                                startDate={this.state.startDate}
                                                endDate={this.state.endDate}
                                                sortOrder={this.state.sortOrder}
                                                datesForTab={this.state.datesForTab}
                                                offset={this.state.pageOffset}

                                            />


                                            <div className="filtering" ref="filters">
                                                <div className="row">

                                                    <div className="col-md-6">
                                                        <div className="search-block">
                                                            <h5 className="font-weight-600">{
                                                                //eslint-disable-next-line
                                                                this.state.resultTitle != '' ? this.state.resultTitle : 'Search Results'}</h5>
                                                            <div className="form-group has-feedback has-search">
                                                            <span
                                                                className="fa fa-search form-control-feedback"></span>
                                                                <input
                                                                    name="searchVal"
                                                                    onChange={this.searchData}
                                                                    type="text"
                                                                    value={this.state.searchVal}
                                                                    className="form-control"
                                                                    placeholder="Search"/>
                                                            </div>

                                                            <div className="search-option">
                                                                <select name="specific"
                                                                        onChange={this.changeHandler}
                                                                        className="form-control">
                                                                    <option value="all">All</option>
                                                                    {   // eslint-disable-next-line
                                                                        this.state.searchOptions.length > 0 && this.state.searchOptions.filter(option => option.value != 'beneficiaryID').map((option, key) => {
                                                                            return <option value={option.value}
                                                                                           key={key}>{option.label}</option>
                                                                        })}

                                                                </select>
                                                            </div>

                                                        </div>
                                                    </div>
                                                    <div className="col-md-2 position-relative d-flex align-center">
                                                        <button onClick={() => window.toggleFilter('.export-dropdown')}
                                                                className="btn btn-custom-primary">
                                                            Export
                                                        </button>
                                                        <div className="export-dropdown">
                                                            <div className="d-flex align-center justify-between">
                                                                <span>Download COVID-19 Tests Results</span>
                                                                <span
                                                                    onClick={() => window.toggleFilter('.export-dropdown')}><img
                                                                    width="15" src={closeIcon} alt=""/></span>
                                                            </div>
                                                            <div className="export-buttons">
                                                                <ReactToPrint
                                                                    trigger={this.renderButton}
                                                                    content={() => this.componentRef}
                                                                    pageStyle={""}/>

                                                                <CSVLink
                                                                    filename={`acda_${new Date().getTime()}`}
                                                                    data={this.state.converting}
                                                                    className="no-border"
                                                                > CSV
                                                                </CSVLink>

                                                                <button onClick={this.generateXLXS}
                                                                        className="no-border">XLSX
                                                                </button>

                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="col-md-4">
                                                        <div className="d-flex align-center justify-right">
                                                            <select value={this.state.pageSize} name="pageSize"
                                                                    onChange={this.changeHandler}>
                                                                <option value="10">10/page</option>
                                                                <option value="25">25/page</option>
                                                                <option value="50">50/page</option>
                                                                <option value="100">100/page</option>
                                                            </select>
                                                            <div className="d-flex justify-right">
                                                                <div className="paginate">

                                                                    {this.state.page > 1 &&
                                                                    <button onClick={this.getPreviousPage}
                                                                            className="btn btn-no-border"><i
                                                                        className="fa fa-angle-left fa-2x"></i></button>
                                                                    }

                                                                    {this.state.tableData.length > 0 &&
                                                                    <div className="options">Page <input name="page"
                                                                                                         onChange={this.changeHandler}
                                                                                                         value={this.state.page}/> of {Math.ceil(this.state.totalLength / this.state.pageSize)}
                                                                    </div>
                                                                    }
                                                                    {Math.ceil(this.state.totalLength / this.state.pageSize) > this.state.page &&
                                                                    <button onClick={this.getNextPage}
                                                                            className="btn btn-no-border"><i
                                                                        className="fa fa-angle-right fa-2x"></i>
                                                                    </button>
                                                                    }
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>

                                                </div>
                                            </div>


                                        </div>
                                        }
                                        {
                                            //eslint-disable-next-line
                                            Object.keys(this.state.barChart).length == 0 && this.state.tableData.length === 0 && this.state.dataLoading == false &&

                                            <div className="not-found text-center"><h3>No results were found.</h3></div>

                                        }

                                    </div>


                                </div>
                            </div>
                        }
                        {   //eslint-disable-next-line
                            this.state.specificCategory != 'beneCategory' &&
                            <div className="row">
                                <div className="col-md-12 text-center">
                                    <h3 className="text-danger">This feature is currently not supported.</h3>
                                </div>
                            </div>
                        }
                    </div>

                </div>


            </React.Fragment>
        )


    }

    renderButton = () => {
        return <button className="no-border">PDF</button>
    }
    renderPrintButton = () => {
        return <button className="btn btn-default"><PrintIcon/></button>
    }
}

export default Home;


