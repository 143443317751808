import React, {Component} from 'react';
import DataTable from "../dataTable";
import './result.css'
class ResultInDataTable extends Component {


    render() {
        return (
            <React.Fragment>

                <DataTable
                    getDetails={this.props.getDetails}
                    dataLoading={this.props.dataLoading}
                    dataElements={this.props.dataElements}
                    direction={this.props.direction}
                    dataSorting={this.props.dataSorting}
                    range={this.props.range}
                    button={this.props.button}
                    data={this.props.data}
                    detailsData={this.props.detailsData}
                    detailsCollections={this.props.detailsCollections}
                    dates={this.props.dates}
                    detail={this.props.detail}
                    dataElementsCollection={this.props.dataElementsCollection}
                    getDetailsByDate={this.props.getDetailsByDate}
                    getDetailBySelectedDate={this.props.getDetailBySelectedDate}
                    keys={this.props.keys}
                    selectedDate={this.props.selectedDate}
                    dateOptions={this.props.dateOptions}
                    pageOffset={this.props.pageOffset}
                    gotoNextDetailsPage={this.props.gotoNextDetailsPage}
                    gotoPrevPage={this.props.gotoPrevPage}
                    detailsLoading={this.props.detailsLoading}
                    detailsModalIsClosed={this.props.detailsModalIsClosed}
                    startDate={this.props.startDate}
                    endDate={this.props.endDate}
                    sortOrder={this.props.sortOrder}
                    datesForTab={this.props.datesForTab}
                    offset={this.props.offset}
                />
            </React.Fragment>
        );
    }
}

export default ResultInDataTable;
