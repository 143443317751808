import React, {Component} from 'react';
import {Bar,defaults} from 'react-chartjs-2'
import 'chartjs-plugin-datalabels';
defaults.global.defaultColor = 'black';
defaults.global.defaultFontColor = '#444';
defaults.global.defaultFontStyle = '600';
defaults.global.defaultFontSize = 13


class BarChart extends Component {

    onElementsClick=(elements)=>{
        this.props.getRangeWiseData(elements)
    }
    render() {
        let thisObj = this;
        return (
            <Bar
                onElementsClick={(elements)=>this.onElementsClick(elements)}
                data={this.props.data && this.props.data }
                width={45}
                height={25}
                options={{
                    scales: {
                        yAxes: [{
                            ticks: {
                                // Include a dollar sign in the ticks
                                callback: function(value, index, values) {
                                    return '$' + value.toFixed(2).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,');
                                }
                            }
                        }]
                    },
                    layout: {
                        padding: {
                            left: 0,
                            right: 0,
                            top: 50,
                            bottom: 0
                        }
                    },

                    tooltips: {
                        callbacks: {
                            label: function(tooltipItem, data) {

                                let price = thisObj.props.customRangeItems
                                //eslint-disable-next-line
                                return ['Total Number of Tests: ' +price[tooltipItem.index].totalNumberOfTests, 'Average Cost of Testing per Beneficiary: ' + '$'+(price[tooltipItem.index].totalCostOfTesting*1 / price[tooltipItem.index].totalNumberOfBenes).toFixed(2).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')]
                            },
                            //eslint-disable-next-line
                            title: function(tooltipItem, data) {
                                return data.labels[tooltipItem[0].index];
                            }
                        }
                    },
                    plugins: {
                        datalabels: {
                            display: true,
                            anchor: 'end',
                            align: 'end',
                            color: '#444',
                            padding:{top: -12},
                            font: {
                                weight: 600,
                                size: 20,
                            },
                            formatter: (value, ctx) => {
                                let price = thisObj.props.customRangeItems
                                //eslint-disable-next-line
                                if(price[ctx.dataIndex].totalCostOfTesting != 0 ) {
                                    return '$' + (price[ctx.dataIndex].totalCostOfTesting * 1 / price[ctx.dataIndex].totalNumberOfBenes ).toFixed(2).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,');
                                }else {
                                    return null
                                }
                            },

                        },

                    },
                    title:{
                        display: false,
                        text: "Total Number of Tests: " + this.props.chart1Title,
                        fontSize: 18
                    },
                    legend:{
                        display: false
                    }

                }}
            />
        );
    }
}

export default BarChart;
