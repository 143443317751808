/* eslint-disable */

import React, {Component} from 'react';
import SummaryDetails from "./summaryDetails/summaryDetails";
import ReactToPrint from 'react-to-print';
import PrintIcon from "./icons/printIcon";
import closeIcon from "./summaryDetails/closeIcon.png";
import moment from "moment";

class DataTable extends Component {
    constructor(props) {
        super(props);
        this.state = {
            activeKey: '',
            details: [],
            detailsCollections: [],
            dates: [],
            selectedId: 0,
            loader: false,
            dateWiseDetails: [],
            perPage: 10,
            page: 1,
            temp: [],
            selectedDate: [],
            beneID: ''


        }
    }

    componentDidMount() {
        //this.getDetailsLabelData()
    }


    showDetails = (id, key,beneID) => {
        if(this.state.activeKey == key){
            this.setState({activeKey: '',beneID: ''})
        }else {
            this.setState({activeKey: key,beneID})
        }
        this.props.getDetails(id)
    }

    dataSorting = (column) => {
        this.props.dataSorting(column)
    }


    setCaret=(element)=>{

        if(element != ''){
            let exist = this.props.sortOrder.find(item=>item.elementCode === element);
            if(exist){
                if(exist.sortDirection == 'ASC'){
                    return <div className="caret-down">&#9660;</div>
                }else {
                    return <div className="crt caret-up">&#9650;</div>
                }
            }
        }
    }



    render() {
        const {details} = this.state;
        const columns = details[0] && Object.keys(details[0])


        return (
            <React.Fragment>


                <div className="vertical-horizontal-scroll" style={{maxHeight: '850px', overflowY: 'auto'}}>
                    {this.props.dataLoading === false && this.props.data.length === 0 &&
                    <div className="not-found text-center"><h3>No results were found.</h3></div>
                    }
                    <table className="table main-table table-striped">

                        <thead>
                        <tr>
                            {   //eslint-disable-next-line
                                this.props.data.length > 0 && Object.keys(this.props.data[0]).filter(row => row != 'key').map((heading, key) => {
                                    return <th className={
                                        //eslint-disable-next-line
                                        key === 0 ? 'text-center' : 'simple'}
                                               key={key}>{
                                        //eslint-disable-next-line
                                        heading == 'id' ? '' : <React.Fragment>
                                            <div
                                                className={heading == 'Age' ? 'float-left margin-left' : 'float-left'}>{heading == 'Total_Tests' ? 'Total # of Tests' : heading}</div>
                                            <div className="shorting float-left"
                                                 onClick={() => this.dataSorting(heading == 'Total Cost of Testing' ? 'TBTCOST' : heading == 'Total_Tests' ? 'TBTCNT' : heading == 'State' ? 'BNST' : heading == 'County' ? 'BNCN' : heading == 'City' ? 'BNCY' : heading == 'Bene ID' ? 'BNMID' : heading == 'Name' ? 'BNNM' : heading == 'Age' ? 'BNAGE' : heading == 'Sex' ? 'BNGN' : heading == 'Race' ? 'BNRC' : '')}>
                                                <div className="carets">{this.setCaret(heading == 'Total Cost of Testing' ? 'TBTCOST' : heading == 'Total_Tests' ? 'TBTCNT' : heading == 'State' ? 'BNST' : heading == 'County' ? 'BNCN' : heading == 'City' ? 'BNCY' : heading == 'Bene ID' ? 'BNMID' : heading == 'Name' ? 'BNNM' : heading == 'Age' ? 'BNAGE' : heading == 'Sex' ? 'BNGN' : heading == 'Race' ? 'BNRC' : '')}</div>
                                            </div>
                                        </React.Fragment>}</th>
                                })}

                        </tr>

                        </thead>
                        <tbody>
                        {this.props.data.length > 0 && this.props.data.map((row, key) => {
                            return <React.Fragment key={key}>
                                <tr data-target="#detailsModal" data-toggle="modal"
                                    onClick={() => this.showDetails(row.id, `col-${key}`,row['Bene ID'])}
                                    className={
                                        //eslint-disable-next-line
                                        this.state.activeKey == `col-${key}` ? "custom-check-box-w checked" : "custom-check-box-w"}>

                                    {   //eslint-disable-next-line
                                        Object.keys(this.props.data[0]).map((column, k) => {
                                            //eslint-disable-next-line
                                            return column == 'id' ? <td key={k} className="text-center check-box">
                                                <div
                                                    className={
                                                        //eslint-disable-next-line
                                                        this.state.activeKey == `col-${key}` ? "custom-check-wrapper shown" : "custom-check-wrapper"}>
                                                    <div className="custom-check-table"></div>
                                                </div>
                                            </td> : <td key={k}>
                                                    <span
                                                        style={column == 'Total Cost of Testing' ? {width:'100px'} : {width:'inherit'}}
                                                        className={column != 'Bene ID' && column != 'Name' && column != 'Sex' && column != 'Race' && column != 'State' && column != 'County' ? 'text-right d-inline' : 'common'}>{   //eslint-disable-next-line
                                                        column == 'Total Cost of Testing' ? (row[column] * 1).toFixed(2).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,') : row[column]}</span>
                                            </td>
                                        })}
                                </tr>


                            </React.Fragment>
                        })}

                        </tbody>
                    </table>

                </div>

                <div className="margin-top-100"></div>

                <div data-backdrop="static" data-keyboard="false" className="modal fade" id="detailsModal">

                    <div className="modal-dialog modal-lg">
                        <div className="modal-content position-relative">

                            {this.props.detail.length > 0 &&
                            <div className="modal-header">
                                <div className="d-flex align-center justify-between">

                                    <p className="font-weight-600">Beneficiary {this.props.detail.length > 0 && this.state.beneID != '' && this.state.beneID} had <span className="text-custom-info">{this.props.detailsData.length}</span> COVID-19
                                        {this.props.detailsData.length  > 1 ? ' tests ' : ' test '}
                                        between <span className="text-custom-info">{moment(this.props.startDate).format('YYYY-MM-DD')} <span style={{color: '#000'}}>and</span> {moment(this.props.endDate).format('YYYY-MM-DD')}</span> </p>

                                    <div className="d-flex align-center">
                                        <ReactToPrint
                                            trigger={this.renderPrintButton}
                                            content={() => this.componentRef}
                                            pageStyle={""}/> &nbsp;  &nbsp;
                                        <button onClick={this.closeModal} className="btn btn-danger">
                                            <img width="15" src={closeIcon} alt="close"/>
                                        </button>
                                    </div>
                                </div>
                            </div>
                            }

                            <SummaryDetails
                                ref={el => (this.componentRef = el)}
                                keys={this.state.keys}
                                selectedDate={this.state.selectedDate}
                                btn={this.renderBtn}
                                gotoNextPage={this.gotoNextPage}
                                gotoPrevPage={this.gotoPrevPage}
                                page={this.state.page} temp={this.state.temp}
                                dateWiseDetails={this.state.dateWiseDetails}
                                getDetailsBySelectedDate={this.getDetailsBySelectedDate} loader={this.state.loader}
                                selectedId={this.state.selectedId} dateOptions={this.state.dates}
                                getDetails={this.getDetails} detailsCollections={this.state.detailsCollections}
                                details={this.state.details}
                                closeModal={this.closeModal}
                                detailsData={this.props.detailsData}
                                detailsCollections={this.props.detailsCollections}
                                dates={this.props.dates}
                                detail={this.props.detail}
                                dataElementsCollection={this.props.dataElementsCollection}
                                getDetailsByDate={this.props.getDetailsByDate}
                                getDetailBySelectedDate={this.props.getDetailBySelectedDate}
                                keys={this.props.keys}
                                selectedDate={this.props.selectedDate}
                                dateOptions={this.props.dateOptions}
                                pageOffset={this.props.pageOffset}
                                gotoNextDetailsPage={this.props.gotoNextDetailsPage}
                                gotoPrevPage={this.props.gotoPrevPage}
                                detailsLoading={this.props.detailsLoading}
                                startDate={this.props.startDate}
                                endDate={this.props.endDate}
                                datesForTab={this.props.datesForTab}
                                offset={this.props.offset}

                            />
                        </div>
                    </div>


                </div>

            </React.Fragment>
        )
    }

    closeModal = () => {
        $('#detailsModal').modal('hide');
        this.setState({
            activeKey: '',
            beneID: ''

        },()=>{
            this.props.detailsModalIsClosed()
        })
    }

    renderPrintButton = () => {
        return <button className="btn btn-default"><PrintIcon/></button>
    }

}

export default DataTable;
