
export const loginAction= {
    login
}
function login(password) {
    let validPassword = 'acda2020!'
    //eslint-disable-next-line
    if(validPassword == password){
        let isAuthenticated = true;
        return isAuthenticated;
    }else{
        let isAuthenticated = false;
        return isAuthenticated
    }

}

