import {Config} from "../config";
import {authHeader} from "../_helpers";
const API_PATH = Config().serverUrl
export const filterServices = {
    getCounty,
    getCity,
    getZipCodes,
    getServiceData,
    getDetails
}

function getCounty(id){
    let requestOptions = {
        method: 'GET',
        headers: authHeader()
    };
    return fetch(API_PATH+'getReference/county/'+id,requestOptions).then(handleResponse)
}
function getCity(id){
    let requestOptions = {
        method: 'GET',
        headers: authHeader()
    };
    return fetch(API_PATH+'getReference/city/'+id,requestOptions).then(handleResponse)
}
function getZipCodes(id){
    let requestOptions = {
        method: 'GET',
        headers: authHeader()
    };
    return fetch(API_PATH+'getReference/zip/'+id,requestOptions).then(handleResponse)
}
function getServiceData(body){
    let requestOptions = {
        method: 'POST',
        headers: authHeader(),
        body: JSON.stringify(body)
    };
    return fetch(API_PATH+'search/getResults/',requestOptions).then(handleResponse)
}
function getDetails(body){
    let requestOptions = {
        method: 'POST',
        headers: authHeader(),
        body: JSON.stringify(body)
    };
    return fetch(API_PATH+'search/getBeneDetails/',requestOptions).then(handleResponse)
}

function handleResponse(response) {
    if(!response.ok){return Promise.reject()}else {return response.json()}
}
