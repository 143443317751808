import React from 'react';
import './App.css';
import Home from "./_pages/home/home";
import {BrowserRouter, Route} from "react-router-dom";
import Login from "./_pages/login/login";
import {PrivateRoute} from "./_components/PrivateRoute";
import {history} from "../src/_helpers";
import {Switch} from "react-router";
import {loginServices} from "./_services";

class App extends React.Component{

    componentDidMount() {
        loginServices.getMetaData().then(data=>{
            localStorage.removeItem('metaData')
            localStorage.setItem('metaData',JSON.stringify(data))
        })
    }


    render() {
    return (
        <BrowserRouter history={history}>
          <Switch>
            <Route exact path="/" component={Login}/>
            <PrivateRoute exact path="/home" component={Home}/>
          </Switch>
        </BrowserRouter>
    )
  }
}

export default App;
