/* eslint-disable */
import React, {Component} from 'react';
import './details.css'
import MultiSelect from "react-multi-select-component";


class SummaryDetails extends Component {

    constructor(props) {
        super(props);
        this.state = {
            nextPage: true,
            prevPage: true,
            key: 0

        }

    }

    closeModal = () => {
        this.props.closeModal();
        this.setState({ selectedDate: []})
    }
    getDetails = (key, date) => {
        this.setState({key}, () => {
            this.props.getDetailsByDate(key,date)
        })
    }

    applyDateFilter = (selectedDate) => {
        this.props.getDetailBySelectedDate(selectedDate)
    }

    gotoPrevPage = () => {
        if (this.props.pageOffset > 1) {
            this.setState({
                prevPage: true,
                nextPage: true
            })
            this.props.gotoPrevPage()

        } else {
            this.setState({prevPage: false})
        }
    }
    gotoNextPage = () => {
        if (this.props.detail.length > 9) {
            this.setState({
                nextPage: true,
                prevPage: true
            })
            this.props.gotoNextDetailsPage()
        } else {
            this.setState({nextPage: false})
        }
    }
    findHeading=(code)=>{
        let exist = this.props.dataElementsCollection.find(dataCode=>dataCode.value === code);
        if(exist){
            return exist.label
        }
    }
    findData=(heading)=>{

        return this.props.detail[0].map(data=>{

            if(data.element === heading){
                if(data.value == null){
                    return '--'
                }else {
                    return data.value
                }
            }
        })
    }
    sortAscendingMode=(dates)=>{
        return dates.sort((a,b)=> new Date(a.label).getTime() - new Date(b.label).getTime() )
    }


    render() {
        const {detailsCollections, detail, id, dates, detailsData, selectedDate,dateOptions,pageOffset,datesForTab} = this.props
        //console.log(Math.ceil(datesForTab.length / 10))

        return (

            <div className="modal-body position-relative">

                {   // eslint-disable-next-line
                    this.props.detailsLoading == true &&
                    <div className="preloader">
                        <span className="fa fa-spinner fa-spin fa-2x"></span>
                    </div>
                }

                {detail.length > 0 &&

                <React.Fragment>
                    <div className="modal-filter d-flex justify-between align-center">
                        {detailsData.length > 10 &&
                        <div>
                            <label className="label_inline">Test Dates</label>
                            {dates.length > 10 &&
                            <MultiSelect
                                options={this.sortAscendingMode(dates)}
                                value={selectedDate}
                                onChange={this.applyDateFilter}
                                placeholderButtonLabel="All Options"
                                labelledBy={"All Options"}
                                selectAllLabel={"All Options"}
                                overrideStrings={{
                                    "selectSomeItems": "All Options",
                                    "allItemsAreSelected": "All Options"
                                }}
                            ></MultiSelect>
                            }
                        </div>
                        }


                    <div className="prev-next d-flex align-center">
                        {detailsData.length > 10 &&
                        <React.Fragment>
                            {pageOffset > 1 &&
                            <button onClick={this.gotoPrevPage} className="btn btn-default">Previous</button>
                            }
                            {Math.ceil(datesForTab.length / 10) > this.props.offset &&
                            <button onClick={this.gotoNextPage} className="btn btn-default">Next</button>
                            }

                        </React.Fragment>
                        }
                    </div>

                </div>
                <div className="margin-top-10"></div>
                    <div className="datewise-result">
                    <div className="result-tab">
                    <ul className="nav nav-tabs">
                    {dateOptions.length > 0 && dateOptions.map((date, key) => {
                        return <li key={key}>
                            <button onClick={() => this.getDetails(key, date.label)}
                                    className={this.props.keys === key ? 'btn btn-default active' : 'btn btn-default'}>Test
                                Date <br/>{date.label}</button>
                        </li>
                    })}


                    </ul>
                    </div>

                    <div className="table-responsive">
                    <table className="table table-striped">
                    {detailsCollections.length > 0 && detailsCollections.map((args, key) => {
                        return <React.Fragment key={key}>
                            <thead>
                            <tr>
                                {detailsCollections[key].filter(heading=>heading != 'BNNM').map((heading, key) => {
                                    return <th key={key}>{this.findHeading(heading)}</th>
                                })}

                            </tr>
                            </thead>
                            <tbody>
                            <tr>
                                {detailsCollections[key].filter(heading=>heading != 'BNNM').map((heading, key) => {
                                    return <th
                                        key={key}>{detailsData.length > 0 && this.findData(heading)}</th>

                                })}
                            </tr>
                            </tbody>
                        </React.Fragment>
                    })}


                    </table>
                    </div>

                    </div></React.Fragment>
                }
            </div>

        );
    }

}

export default SummaryDetails;
