import React, {Component} from 'react';
import BarChart from "../barChart";
import PieChart from "../pieChart";
import './result.css'
class ResultInChart extends Component {
    render() {
        return (
            <div className="row" style={{marginBottom: '72px'}}>

                <div className="col-md-6">
                    <div className="background-white">

                        <h5 className="text-center font-weight-600 text-custom-info text-size-16">Average Cost of Testing Per Beneficiary By
                            Testing Frequency Range</h5>
                        <div className="chart-block">

                            <div className="margin-top-30"></div>
                            <div className="row">
                                <div className="col-md-1 text-right right">
                                    {/*<h5 style={{writingMode: 'tb-rl'}}>Total &nbsp; Number &nbsp; of &nbsp; Tests</h5>*/}
                                    <h5 style={{writingMode: 'tb-rl'}}>Average Cost of Testing per Beneficiary</h5>
                                </div>
                                <div className="col-md-10">

                                    <div className="chart-2">
                                        <BarChart barChartTitle={this.props.barChartTitle}
                                                  getRangeWiseData={this.props.getRangeWiseData}
                                                  customRangeItems={this.props.customRangeItems}
                                                  data={this.props.barChart}/>
                                    </div>

                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="col-md-6">
                    <div className="background-white">

                        <h5 className="font-weight-600  text-custom-info text-center text-size-16">Total Percentage of COVID-19 Tests Completed By Testing
                            Frequency Range</h5>
                        <div className="chart-block">

                            <div className="margin-top-30"></div>
                            <div className="chart-1">
                                <PieChart
                                    range={this.props.range}
                                    getRangeWiseData={this.props.getRangeWiseData}
                                    customRangeItems={this.props.customRangeItems}
                                    data={this.props.pieChart}/>
                            </div>
                        </div>
                    </div>

                </div>
                <div className="margin-top-30"></div>

                <div className="col-md-12">
                    <div className="text-center text-custom-info">
                        <h5 className="text-size-16 guide-text"><i className="fa fa-info-circle"></i>  Please click the bar chart columns to filter table results by the specific testing frequency range.</h5>
                    </div>
                </div>
            </div>
        );
    }
}

export default ResultInChart;
